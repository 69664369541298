
import { GetAbout } from '@/api/about'

const state = {
  aboutItem: {} // 关于我们信息
}

const mutations = {
  setaboutItem (state, item) {
    state.aboutItem = item
  }
}

const actions = {
  // 获取关于我们信息
  async getabout ({ commit }) {
    const res = await GetAbout()
    // console.log(res)
    commit('setaboutItem', res.results[0])
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
