import request from '@/utils/request'

// 获取经典案例类型信息
export const GetCases = () => request.get('/cases/caseTypes/')

// 获取案例展示的数据
export const GetCaseItem = (data) => request.get('/cases/displays/', { params: data })

// 获取案例展示的详情
export const GetCaseDetail = (id) => request.get(`/cases/displays/${id}/`)
