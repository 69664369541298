import Vue from 'vue'
import Vuex from 'vuex'
import company from './modules/company'
import home from './modules/home'
import serve from './modules/serve'
import cases from './modules/cases'
import contact from './modules/contact'
import about from './modules/about'
Vue.use(Vuex)

export default new Vuex.Store({

  modules: {
    company,
    home,
    serve,
    cases,
    contact,
    about
  }
})
