
import { GetIndex, GetBanner } from '@/api/home'

const state = {
  indexItem: {}, // 首页信息
  banner: [] // 轮播图
}

const mutations = {
  setindexItem (state, item) {
    state.indexItem = item
  },
  setbanner (state, item) {
    state.banner = item
  }
}

const actions = {
  // 获取首页信息
  async getindex ({ commit }) {
    const res = await GetIndex()
    // console.log(res)
    commit('setindexItem', res.results)
  },
  // 获取轮播图信息
  async getbanner ({ commit }) {
    const res = await GetBanner()
    // console.log(res)
    commit('setbanner', res.results)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
