import { Tabbar, TabbarItem, Swipe, SwipeItem, Popup, Pagination, NavBar, Toast } from 'vant'
import Vue from 'vue'
import HeadNav from '@/components/head-nav.vue'
import FooterNav from '@/components/footer-nav.vue'

import BaiduMap from 'vue-baidu-map'
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Popup)
Vue.use(Pagination)
Vue.use(NavBar)
Vue.use(Toast)
// 全局注册组件
Vue.component('HeadNav', HeadNav)
Vue.component('FooterNav', FooterNav)
Vue.use(BaiduMap, {
  ak: 'xRr37vc5LX8DKcAP2c9NqEmksY39QYvX' // 这个地方是官方提供的ak密钥
})
Vue.prototype.$toast = Toast
