<template>
<div>
    <div class="head_nav">
      <div class="item" v-if="$store.state.company.companyItem.logo">
        <img @click="$router.push('/index')" :src="$store.state.company.companyItem.logo" alt="">
        <img @click="showPOp" src="../assets/images/mbx.png" alt="">
      </div>
    </div>
    <van-popup v-model="show" closeable position="right" :style="{ width: '50%',height:'100% '}" >
      <div class="popup_item">
        <div class="top" v-if="$store.state.company.companyItem.logo">
          <img :src="$store.state.company.companyItem.logo" alt="">
        </div>
        <div class="bot">
          <ul>
            <li :class="{active:index===checked}" @click="changeNav(index,item.path)" v-for="(item,index) in navItem" :key="item.id">{{item.name}}</li>
          </ul>
          <div class="lian">
            <img src="../assets/images/phone.png" alt="">
            <p>{{$store.state.company.companyItem.helpline}}</p>
          </div>
        </div>
      </div>
    </van-popup>
</div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      checked: 0,
      navItem: []// 导航栏信息
    }
  },
  created () {
    if (this.$route.path === '/index') {
      this.checked = 0
    }
    if (this.$route.path === '/serve') {
      this.checked = 1
    }
    if (this.$route.path === '/case') {
      this.checked = 2
    }
    if (this.$route.path === '/contact') {
      this.checked = 3
    }
    if (this.$route.path === '/about') {
      this.checked = 4
    }

    if (!this.$store.state.company.navItem.length) {
      this.getnav()
    } else {
      this.navItem = this.$store.state.company.navItem
    }
  },
  methods: {
    showPOp () {
      this.show = true
    },
    changeNav (index, path) {
      this.checked = index
      this.$router.push(path)
    },
    // 获取导航栏模块信息
    async getnav () {
      await this.$store.dispatch('company/getnav')
      this.navItem = this.$store.state.company.navItem
      // console.log(this.navItem)
    }

  }
}
</script>

<style lang="less" scoped>
.head_nav{
  // background-color: red;
  height:44px ;
  width: 100%;
  .item{
    height:44px ;
    padding: 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
    width: 112px;
    height: auto;
    &:last-child{
      width: 23px;
      height: auto;
    }
  }
  }
}
/deep/ .van-popup{
  .popup_item{
    .top{
      padding: 10px 20px;
      img{
        width: 80%;
        height: auto;
      }
    }
    .bot{
      padding:0px 20px;
      ul{
        li{
          height: 50px;
          line-height: 50px;
          border-bottom: 1px solid #D9D9D9;
        }
      }
      .lian{
        display: flex;
        margin-top: 25px;
        img{
          width: 20px;
          height: 20px;
          margin-right: 15px;
        }
        p{
          font-size: 16px;
          color: #0097FF;
        }
      }
    }
  }
}
//切换的样式
.active{
  font-weight: bold;
  font-size: 16px;
  color: #2174CA;
}
</style>
