
import { GetCases, GetCaseItem, GetCaseDetail } from '@/api/cases'

const state = {
  casestype: [], // 案例类型
  caseItem: {} // 案例展示的数据

}

const mutations = {
  setcasestype (state, item) {
    state.casestype = item
  },
  setcaseItem (state, item) {
    state.caseItem = item
  }
}

const actions = {
  // 获取案例类型信息
  async getcasestype ({ commit }) {
    const res = await GetCases()
    // console.log(res)
    commit('setcasestype', res.results)
  },
  // 获取案例展示的数据
  async getcaseitem ({ commit }, item) {
    const res = await GetCaseItem(item)
    // console.log(res)
    commit('setcaseItem', res)
  },
  // 获取案例展示的详情数据
  async getcasedetail ({ commit }, id) {
    const res = await GetCaseDetail(id)
    return res
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
