import router from '../router'

router.beforeEach((to, from, next) => {
  if (to.meta.content) {
    // console.log(to.meta.content)
    const head = document.getElementsByTagName('head')[0]
    // console.log(head)
    // 删除现有名称为 "keywords" 和 "description" 的 meta 标签
    const existingKeywordsMeta = document.querySelector('meta[name="keywords"]')
    if (existingKeywordsMeta) {
      existingKeywordsMeta.remove() // 移除现有的关键词 meta 标签
    }
    const existingDescriptionMeta = document.querySelector('meta[name="description"]')
    if (existingDescriptionMeta) {
      existingDescriptionMeta.remove() // 移除现有的描述 meta 标签
    }

    // 创建并设置新的关键词和描述 meta 标签
    const keywordsMeta = document.createElement('meta')
    keywordsMeta.setAttribute('name', 'keywords')
    keywordsMeta.setAttribute('content', to.meta.content.keywords)
    head.appendChild(keywordsMeta) // 添加新的关键词 meta 标签

    const descriptionMeta = document.createElement('meta')
    descriptionMeta.setAttribute('name', 'description')
    descriptionMeta.setAttribute('content', to.meta.content.description)
    head.appendChild(descriptionMeta) // 添加新的描述 meta 标签

    // 可选：如果需要，更新名为 "content" 的其他 meta 标签
    const contentMeta = document.querySelector('meta[name="content"]')
    if (contentMeta) {
      contentMeta.setAttribute('content', to.meta.content) // 更新 content 属性
    }
  }
  // /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 判断设备是否是移动端
  const userAgent = navigator.userAgent || navigator.vendor || window.opera
  const showPhone = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
  if (showPhone) {
    next()
  } else {
    // 如果监测不是手机端 则跳转到PC端官网页面
    window.location.href = 'https://gongsi.taoyuer.com/'
  }
  // next()
})
