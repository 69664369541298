
import { GetContact, SubItem } from '@/api/contact'

const state = {
  contactItem: {} // 联系我们信息
}

const mutations = {
  setcontactItem (state, item) {
    state.contactItem = item
  }
}

const actions = {
  // 获取联系我们信息
  async getcontact ({ commit }) {
    const res = await GetContact()
    // console.log(res)
    commit('setcontactItem', res.results[0])
  },
  // 添加留言
  async subitem ({ commit }, item) {
    const res = await SubItem(item)
    return res
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
