import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

// 触发 获取公司模块的信息
await store.dispatch('company/getcompany')
const temp = store.state.company.companyItem
// console.log(temp, 888)
const routes = [
  // 打包后会出现空白，没重定向
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    component: () => import('@/views/home-page.vue'),
    meta: {
      title: temp.myTitle,
      content: {
        keywords: temp.keywords,
        description: temp.description
      }
    }
  },
  {
    path: '/serve',
    component: () => import('@/views/serve-page.vue')
  },
  {
    path: '/case',
    component: () => import('@/views/case-page.vue')
  },
  {
    path: '/case-detail',
    component: () => import('@/views/details/case-detail.vue')
  },
  {
    path: '/contact',
    component: () => import('@/views/contact-page.vue')
  },
  {
    path: '/about',
    component: () => import('@/views/about-page.vue')
  }
]

const router = new VueRouter({
  routes,
  // 路由切换页面的滚动行为
  scrollBehavior (to, from, savedPosition) {
    // if (savedPosition) {
    //   // 如果之前有保存滚动位置，则恢复到之前保存的位置
    //   return savedPosition
    // } else {
    // 否则，默认滚动到页面顶部
    return { x: 0, y: 0 }
    // }
  }
})
// 修复 重复渲染路由 报错
// 获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
