
import { GetCompany, GetNav } from '@/api/company'

const state = {
  companyItem: {}, // 公司模块信息
  navItem: [] // 导航栏模块
}

const mutations = {
  setcompanyItem (state, item) {
    state.companyItem = item
  },
  setnavItem (state, item) {
    state.navItem = item
  }
}

const actions = {
  // 获取公司模块的信息
  async getcompany ({ commit }) {
    const res = await GetCompany()
    // console.log(res)
    commit('setcompanyItem', res.results[0])
  },
  // 获取导航栏模块
  async getnav ({ commit }) {
    const res = await GetNav()
    // console.log(res)
    commit('setnavItem', res.results)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
