
import { GetServeImg, GetServeContent } from '@/api/serve'

const state = {
  serveItem: {}, // 服务 信息
  serveImg: {} // 服务 图片
}

const mutations = {
  setserveItem (state, item) {
    state.serveItem = item
  },
  setserveImg (state, item) {
    state.serveImg = item
  }
}

const actions = {
  // 获取服务信息
  async getserveItem ({ commit }) {
    const res = await GetServeContent()
    // console.log(res)
    commit('setserveItem', res.results)
  },
  // 获取服务图片
  async getserveimg ({ commit }) {
    const res = await GetServeImg()
    // console.log(res)
    commit('setserveImg', res.results[0])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
