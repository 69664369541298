<template>
  <div>
      <div class="footer" v-if="companyItem.logo">
        <div class="foot_item">
          <img :src="companyItem.gs_ewm" alt="">
          <h4>{{companyItem.name}}</h4>
          <p>电话：<a :href="`tel:${companyItem.phone}`">{{companyItem.phone}}</a></p>
          <p>邮箱：{{companyItem.email}}</p>
          <p style="text-align:center">地址：{{companyItem.address}}</p>
        </div>
        <div class="bei">
          <p>Copyright © {{companyItem.time}} {{companyItem.name}}</p>
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">
          <p>{{companyItem.ICP}} 版权所有</p></a>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      companyItem: {} // 公司模块信息

    }
  },
  created () {
    if (!this.$store.state.company.companyItem.name) {
      this.getcompany()
    } else {
      this.companyItem = this.$store.state.company.companyItem
    }
  },
  methods: {
    // 获取公司模块的信息
    async getcompany () {
      await this.$store.dispatch('company/getcompany')
      this.companyItem = this.$store.state.company.companyItem
      // console.log(this.companyItem)
    }
  }
}
</script>

<style lang="less" scoped>
//底部区域
.footer{
  .foot_item{
    padding-top: 15px;
    width: 300px;
    height: 200px;
    // background-color: yellow;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img{
      width: 86px;
      height: 86px;
    }
    h4{
      font-weight: normal;
      font-size: 14px;
      color: #666666;
    }
    p{
      font-size: 13px;
      color: #666666;
      a{
        color: #666666;
      }
    }
  }
  .bei{
      margin-top: 20px;
      display: block;
      width: 375px;
      height: 60px;
      color: #fff;
      background-color:#2174CA ;
      font-size: 12px;
      text-align: center;
      line-height:30px;
      a{
        p{
          color: #fff;
        }
      }
  }
}
</style>
